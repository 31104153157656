<template>
    <div class="flex flex-col flex-grow">
        <a-spin 
            :spinning="!render" 
            class="mt-4 flex justify-center" />
        <TaskList
            v-if="render"
            main
            extendDrawer
            model="tasks.TaskModel"
            :taskType="taskType"
            :showAddButton="isStudent"
            :columnNameWidth="200"
            :pageConfig="pageConfig"
            :hash="false"
            :actionFix="false"
            :formParams="formParams"
            :queryParams="queryParams"
            :name="`workgroups_${this.id}`" >
            <PageFilter 
                model="tasks.TaskModel"
                :key="pageName"
                size="large"
                :excludeFields="excludeFields"
                :page_name="pageName"/>
        </TaskList>
    </div>
</template>

<script>
import PageFilter from '@/components/PageFilter'
import TaskList from '@apps/vue2TaskComponent/components/TaskList/TaskList'
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
        TaskList,
        PageFilter
    },
    props: {
        id: {
            type: [String, Number],
            required: true
        },
        isStudent: Boolean,
        isFounder: Boolean,
        taskType: {
            type: String,
            default: 'task'
        },
        actions: {
            type: Object,
            default: () => null
        }
    },
    async created() {
        if(this.actions?.create_task) {
            this.pageConfig.headerButtons = {
                createButton: {
                    fastCreate: true,
                    icon: "plus",
                    show: true,
                    size: "large",
                    title: "Добавить задачу",
                    type: "primary"
                }
            }
        }

        setTimeout(() => {
            if(this.requestData.is_project) {
                this.formParams = { 
                    project: {
                        name: this.requestData.name, 
                        id: this.id,
                        workgroup_logo: this.requestData.workgroup_logo?.is_image ? this.requestData.workgroup_logo : null,
                        date_start_plan: this.requestData.date_start_plan,
                        dead_line: this.requestData.dead_line
                    }
                }
                this.queryParams = { filters: { project: this.id } }
                this.queryParams['page_name'] = this.pageName
                this.excludeFields = ['project']
                this.render = true
            } else {
                this.formParams = { 
                    workgroup: {
                        name: this.requestData.name, 
                        id: this.id,
                        workgroup_logo: this.requestData.workgroup_logo?.is_image ? this.requestData.workgroup_logo : null
                    }
                }
                this.queryParams = { filters: { workgroup: this.id } }
                this.queryParams['page_name'] = this.pageName
                this.excludeFields = ['workgroup']
                this.render = true
            }
        }, 1000)
    },
    data() {
        return{
            formParams: {},
            queryParams: {},
            excludeFields: [],
            page_name: "tasks.groups_and_project",
            render: false,
            pageConfig: {
                showFilter: true
            }
        }
    },
    computed:{
        ...mapGetters({
            requestData : "workgroups/info"
        }),
        pageName() {
            // WARNING
            if(this.taskType === 'interest')
                return `interest.groups_and_project_${this.id}`
            // WARNING END
            
            return `tasks.groups_and_project_${this.id}`
        }
    },
    methods: {
        ...mapActions({
            getInfo: "workgroups/getInfo"
        })
    }
}
</script>