<template>
    <div>
        <MainPage :isProject="false"/>
    </div>
</template>

<script>
import  MainPage from '../MainPage'
export default {
    name: "GroupPage",
    components: {
        MainPage
    },
    created() {},
    data() {
        return {};
    },
    props: {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>