<template>
    <div class="kanban">
        <template  v-if="!isMobile">
            <button 
                v-show="leftActive"
                class="arrow_left arrow"
                @mouseenter="scrollLeft"
                @mouseleave="clear">
                <i class="fi fi-rr-angle-small-left"></i>
            </button>
            <button 
                v-show="rightActive"
                class="arrow_right arrow"
                @mouseenter="scrollRight"
                @mouseleave="clear">
                <i class="fi fi-rr-angle-small-right"></i>
            </button>
        </template>
        <template>
            <template v-if="!isMobile" >
                <div class="flex items-center pb-4 kanban_button">
                    <AddButton
                        v-if="addButton"
                        :formParams="formParams"
                        :addButton="addButton"
                        :windowWidth="windowWidth" />
                    <div class="ml-2">
                        <slot />
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="float_add">
                    <div class="filter_slot">
                        <slot />
                    </div>
                    <AddButton
                        v-if="addButton"
                        :formParams="formParams"
                        :addButton="addButton"
                        buttonType="circle"
                        :windowWidth="windowWidth" />
                </div>
            </template>
        </template>

        <div class="kanban_container">
            <div 
                class="kanban_wrapper" 
                ref="kanbanWrapper"
                v-scroll="handleScroll">
                <div v-if="statusLoader" />
                <div 
                    v-else 
                    class="kanban-main">
                    <span class="scroll_dummy"></span>
                    <Column 
                        v-for="column in columns" 
                        :key="column.code" 
                        :column="column"
                        :selectElement="selectElement"
                        :setSelectElement="setSelectElement"
                        :taskType="taskType"
                        :queryParams="queryParams"
                        :implementId="implementId"
                        :implementType="implementType" />
                    <span class="scroll_dummy"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AddButton from "../AddButton.vue"
import Column from './Column.vue'
import config from '../mixins/config.js'
import { vScroll } from '@vueuse/components'
import { useScroll, onKeyStroke } from '@vueuse/core'
export default {
    name: "Kanban",
    mixins: [
        config
    ],
    components: {
        AddButton,
        Column
    },
    directives: {
        scroll: vScroll
    },
    props: {
        implementId: {
            type: [String, Number],
            default: null
        },
        implementType: {
            type: String,
            default: ''
        },
        formParams: { // Заполнитель данных в форме по умолчанию
            type: Object,
            default: () => {}
        },
        queryParams: {
            type: Object,
            default: () => null
        },
        taskType: {
            type: String,
            default: 'task'
        },
        extendDrawer: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth,
            statusList: state => state.task.statusList,
            statusLoader: state => state.task.statusLoader,
            isMobile: state => state.isMobile
        }),
        columns() {
            if(this.statusList?.[this.taskType]?.length)
                return this.statusList[this.taskType]
            else
                return []
        },
        filters() {
            if(this.implementId)
                return {
                    [this.implementType]: this.implementId
                }
            else
                return null
        }
    },
    data() {
        return {
            loading: false,
            oldStatus: "",
            selectElement: null,
            oldQuery: {},
            leftActive: true,
            rightActive: true,
            timer: null
        }
    },
    created(){
        this.getStatus()
        if(this.$route.query.task){
            this.oldQuery = this.$route.query
        }
    },
    watch: {
        '$route.query'(val){
            this.oldQuery = val
        },
    },
    methods: {
        ...mapActions({
            getStatusList: 'task/getStatusList'
        }),
        async getTaskActions() {
            try {
                await this.$store.dispatch('task/getTaskActions', {
                    task_type: this.taskType
                })
            } catch(e) {
                this.$message.error(this.$t('error'))
            }
        },
        setSelectElement(item) {
            this.selectElement = item
        },
        async getStatus() {
            try {
                await this.getStatusList({ task_type: this.taskType })
            } catch(e) {
                console.log(e)
            }
        },
        scrollLeft(){
            this.timer = setInterval(() => {
                this.$refs.kanbanWrapper.scrollLeft -= 5
            }, 10)
        },
        scrollRight(){
            this.timer = setInterval(() => {
                this.$refs.kanbanWrapper.scrollLeft += 5
            }, 10)
        },
        clear(){
            clearInterval(this.timer)
        },
        handleScroll(e) {
            this.leftActive = !e.arrivedState.left
            this.rightActive = !e.arrivedState.right
        }
    },
    mounted() {
        this.$nextTick(() => {
            const { arrivedState } = useScroll(this.$refs.kanbanWrapper)
            this.leftActive = !arrivedState.left
            this.rightActive = !arrivedState.right
        })

        onKeyStroke(['a', 'A', 'ArrowLeft'], () => {
            this.$refs.kanbanWrapper.scrollLeft -= 20
        })
        onKeyStroke(['d', 'D', 'ArrowRight'], () => {
            this.$refs.kanbanWrapper.scrollLeft += 20
        }, { dedupe: true })
    }
}
</script>

<style scoped lang="scss">
.arrow{
    @media (max-width: 600px) {
            display: none;
    }
    width: 30px;
    height: 50px;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    z-index: 50;
    opacity: 0.4;
    outline: none;
    color: #ffffff;
    background: #000000;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        opacity: 0.6;
    }
    &.arrow_left{
        border-radius: 0px 30px 30px 0px;
    }
    &.arrow_right_main,
    &.arrow_right{
        &:not(.arrow_right_main) {
            // left: 0;
                right: 0;
            margin-left: -20px;
        }
        border-radius: 30px 0px 0px 30px;
    }
    &.arrow_right_main{
        right: 15px;
        margin-top: -25px;
    }
}
.kanban{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    .kanban_button{
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 20px;
    }
    .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    }
    .ghost{
        opacity: 0.5;
        background: #c8ebfb;
        &:not(:last-child){
            margin-bottom: 15px;
        }
    }
}

.kanban_wrapper{
    position: relative;
    padding-bottom: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-x: contain;
    flex-direction: column;
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    height: 100%;
    &::-webkit-scrollbar{
        height: 7px;
    }
    @media(min-width: 981px){
        scroll-padding: 40px;
    }
    @media(max-width: 980px){
        scroll-padding: 20px;
        scroll-snap-type: x mandatory;
    }
}
.kanban_container{
    flex-grow: 1;
    height: calc(100% - 76px);
}
.kanban-main{
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    height: 100%;
    .scroll_dummy{
        @media(min-width: 981px){
            min-width: 30px;
        }
        @media(max-width: 980px){
            min-width: 15px;
        }
    }
}
</style>

<style lang="scss">
.scroll_block{
    .__view{
        height: 100%;
    }
}
.item-list{
    .ant-card{
        box-shadow: 0 1px 0 rgba(9, 30, 66, 0.15);
        border: 0px;
    }

}

.hide_item{
    min-width: 50px !important;
    max-width: 50px !important;

    .wrapper-item{
        display: none;
    }


    .item-title{

        padding: 0;
        margin: 0;

        height: 100%;
        transform: rotate(-90deg);

        display: flex;

        .title-badge{
            min-width: 33vh;
            .count{
                   transform: rotate(90deg);
                   margin-left: 20px;
            }

            @media(max-height: 580px) {
                min-width: 30vh;
            }
            @media (min-height: 800px) {
                min-width: 35vh;
            }
            @media (min-height: 900px) {
                min-width: 37vh;
            }
            @media (min-height: 1000px) {
                min-width: 38vh;
            }

        }
        button{
            transform: rotate(270deg);
        }
    }
}
</style>
